<template>
  <div class="fluid" id="requestList" v-if="permission.read_perm === 1">
    <div>
      <div
        style="width: 100%; background:#00bcd4;position:sticky; top:60px;z-index:3;"
      >
        <h5
          style="padding-top:5px;font-weight:bold;margin:auto;text-align:center; color:white;"
        >
          ARCHIVE MANAGEMENT SYSTEM
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="refresh"
              >
                <v-icon small color="white">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
        </h5>
      </div>
      <div v-if="isAdmin">
        <div class="request-list-div">
          <!-- <div style="margin-bottom: 25px; width: 100%">
          <h3
            style="font-weight:bold;margin:auto;text-align:center;color:indigo;"
          >
            ARCHIVE MANAGEMENT SYSTEM
          </h3>
        </div> -->

          <v-row no-gutters style="width:100%; margin-bottom: 20px;">
            <v-col cols="12" class="px-5">
              <div
                style="
            border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
            margin-right: auto;
            margin-bottom: 10px;
            width: 100%;
          "
              >
                <p
                  style="
              color: indigo;
              margin: 0;
              font-size: 18px;
              font-weight:bold;
            "
                >
                  ARSIP KADALUARSA
                </p>
              </div>
              <v-card
                class="px-5 py-3"
                style="
            border: 1px solid rgba(0, 0, 0, 0.2);
            width: 100%;
          "
              >
                <!-- <v-row no-gutters>
            <v-col cols="12"> -->
                <div style=" overflow:auto;">
                  <v-data-table
                    mobile-breakpoint="0"
                    fixed-header
                    height="37vh"
                    class="elevation-1"
                    style="cursor: pointer; min-width:1200px;"
                    :headers="headers"
                    :items="result"
                    :items-per-page="5"
                    :loading="loading"
                    @click:row="rowClick"
                    item-key="id"
                  >
                    <template v-slot:[`item.plant`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ item.plant !== null ? item.plant.name : '' }}
                      </div>
                    </template>
                    <template v-slot:[`item.doc_expiry_date`]="{ item }">
                      <td
                        class="arch-expDate"
                        v-if="item.document_type_id !== 'Permanent'"
                      >
                        <div class="arch-exp-date">
                          <div style="width: 120px; padding: 0 5px">
                            <p style="margin: 0">
                              {{
                                item.document_type_id === 'Permanent'
                                  ? ''
                                  : convertDate(item.doc_expiry_date)
                              }}
                            </p>
                          </div>

                          <div
                            class="arch-exp-date-notif"
                            v-if="countdown(item.doc_expiry_date) <= '7'"
                          >
                            <div class="exp-date-notif-div">
                              <v-chip
                                class="exp-date-notif-chip1"
                                v-if="countdown(item.doc_expiry_date) > '0'"
                                small
                                color="orange"
                                text-color="white"
                              >
                                Expire in
                                {{ countdown(item.doc_expiry_date) }} days
                              </v-chip>
                              <v-chip
                                class="exp-date-notif-chip2"
                                v-else
                                small
                                color="red"
                                text-color="white"
                              >
                                <p class="date-notif-chip2-p">Expired</p>
                              </v-chip>
                            </div>
                          </div>
                        </div>
                      </td>
                    </template>
                    <!-- <template v-slot:[`item.created_at`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.created_at) }}
                  </div>
                </template> -->
                  </v-data-table>
                </div>
                <div class="pr-items-add-new-line">
                  <DetailDocument
                    :result="detail"
                    @close="clear"
                    :permission="permission"
                  />
                </div>
                <!-- </v-col>
          </v-row> -->
              </v-card>
            </v-col>
          </v-row>

          <v-row no-gutters style="width:100%; margin-bottom: 0px;">
            <v-col cols="12" md="6" class="px-5" style="margin-bottom: 20px;">
              <div
                style="
            border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
            margin-right: auto;
            width: 100%;
            margin-bottom: 10px;
          "
              >
                <p
                  style="
              color: indigo;
              margin: 0;
              font-size: 18px;
              font-weight: bold;
            "
                >
                  {{ result3.length }} PEMINJAMAN BUTUH APPROVAL
                </p>
              </div>
              <v-card
                class="px-5 py-3"
                style="
            border: 1px solid rgba(0, 0, 0, 0.2);
            width: 100%;
          "
              >
                <div style=" overflow:auto;">
                  <v-data-table
                    mobile-breakpoint="0"
                    fixed-header
                    height="25vh"
                    class="elevation-1"
                    style="cursor: pointer; min-width:1200px;"
                    :headers="headers2"
                    :items="result3"
                    :items-per-page="5"
                    :loading="loading2"
                    @click:row="rowClick2"
                    item-key="id"
                  >
                    <template v-slot:[`item.loan_start_date`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ convertDate2(item.loan_start_date) }}
                      </div>
                    </template>

                    <template v-slot:[`item.loan_end_date`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ convertDate2(item.loan_end_date) }}
                      </div>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ item.status !== null ? item.status.name : 'null' }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="6" class="px-5" style="margin-bottom: 10px;">
              <div
                style="
            border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
            margin-right: auto;
            width: 100%;
            margin-bottom: 10px;
          "
              >
                <p
                  style="
              color: indigo;
              margin: 0;
              font-size: 18px;
              font-weight: bold;
            "
                >
                  PEMINJAMAN YANG AKAN BERAKHIR
                </p>
              </div>
              <v-card
                class="px-5 py-3"
                style="
            border: 1px solid rgba(0, 0, 0, 0.2);
            width: 100%;
          "
              >
                <div style=" overflow:auto;">
                  <v-data-table
                    mobile-breakpoint="0"
                    fixed-header
                    height="25vh"
                    class="elevation-1"
                    style="cursor: pointer; min-width:1200px;"
                    :headers="headers2"
                    :items="result2"
                    :items-per-page="5"
                    :loading="loading2"
                    @click:row="rowClick2"
                    item-key="id"
                  >
                    <template v-slot:[`item.loan_start_date`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ convertDate2(item.loan_start_date) }}
                      </div>
                    </template>

                    <template v-slot:[`item.loan_end_date`]="{ item }">
                      <td class="arch-expDate">
                        <div class="arch-exp-date">
                          <div style="width: 120px; padding: 0 5px">
                            <p style="margin: 0">
                              {{ convertDate2(item.loan_end_date) }}
                            </p>
                          </div>

                          <div
                            class="arch-exp-date-notif"
                            v-if="countdown2(item.loan_end_date) <= '7'"
                          >
                            <div class="exp-date-notif-div">
                              <v-chip
                                class="exp-date-notif-chip1"
                                v-if="countdown2(item.loan_end_date) > '0'"
                                small
                                color="orange"
                                text-color="white"
                              >
                                Done in
                                {{ countdown2(item.loan_end_date) }} days
                              </v-chip>
                              <v-chip
                                class="exp-date-notif-chip2"
                                v-else-if="countdown2(item.loan_end_date) < '0'"
                                small
                                color="red"
                                text-color="white"
                              >
                                <p class="date-notif-chip2-p">
                                  exceeded
                                </p>
                              </v-chip>
                              <v-chip
                                class="exp-date-notif-chip2"
                                v-else
                                small
                                color="pink"
                                text-color="white"
                              >
                                <p class="date-notif-chip2-p">Done</p>
                              </v-chip>
                            </div>
                          </div>
                        </div>
                      </td>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ item.status !== null ? item.status.name : 'null' }}
                      </div>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      <div
                        style="padding: 0; display: flex; flex-direction: row"
                      >
                        {{ convertDate3(item.created_at) }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-if="!isAdmin">
      <UserView :permission="permission" />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    DetailDocument: () =>
      import('../../../components/archive-management/archive-manage/Detail'),
    UserView: () =>
      import('../../../components/archive-management/dashboard/UserView')
  },
  data: () => ({
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: null,
    // menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 27 : 28,
    menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 28 : 28,
    application: {
      id: 14,
      name: 'eArchive'
    },
    permission: {},
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 10000,
      status: ''
    },
    headers: [
      {
        text: 'No. Arsip',
        value: 'archive_doc_no',
        align: 'left',
        sortable: true
      },
      {
        text: 'No. Dokumen',
        value: 'doc_no',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama Dokumen',
        value: 'name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Kepemilikan',
        value: 'plant',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl Expired',
        value: 'doc_expiry_date',
        align: 'left',
        sortable: true
      }
      // {
      //   text: 'Created at',
      //   value: 'created_at',
      //   align: 'left',
      //   sortable: true
      // },
      // {
      //   text: 'Created by',
      //   value: 'created_by_name',
      //   align: 'left',
      //   sortable: true
      // },
      // {
      //   text: 'Status',
      //   value: 'doc_status.name',
      //   align: 'left',
      //   sortable: true
      // }
    ],
    result: [],
    loading: false,
    detail: null,

    paramAPI2: {
      keyword: '',
      offset: 0,
      limit: 10000,
      status: ''
    },
    headers2: [
      {
        text: 'No. Peminjaman',
        value: 'loan_number',
        align: 'left',
        filterable: false,
        sortable: true
      },
      // {
      //   text: 'No. Dokumen',
      //   value: 'doc.doc_no',
      //   align: 'left',
      //   sortable: true
      // },
      // {
      //   text: 'Nama Dokumen',
      //   value: 'doc.name',
      //   align: 'left',
      //   sortable: true
      // },
      {
        text: 'PIC',
        value: 'pic_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Pinjam',
        value: 'loan_start_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Selesai',
        value: 'loan_end_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Dibuat',
        value: 'created_at',
        align: 'left',
        sortable: true
      }
      // {
      //   text: 'Status',
      //   value: 'status',
      //   align: 'left',
      //   sortable: true
      // }
    ],
    result2: [],
    loading2: false,
    result3: [],
    tabs: [
      { tab_name: 'Dashboard', path: '/archive-management' },
      { tab_name: 'Arsip', path: '/archive-management/document-list' },
      {
        tab_name: 'Peminjaman',
        path: '/archive-management/loaning-list'
      },
      {
        tab_name: 'Master',
        path: '',
        isShown: false,
        sub_tabs: [
          {
            sub_title: 'Kategori',
            path: '/archive-management/document-category'
          },
          {
            sub_title: 'Sub Kategori',
            path: '/archive-management/document-subcategory'
          }
        ]
      }
      // {
      //   tab_name: 'Pengeluaran',
      //   path: '/archive-management/document-release'
      // },
      // {
      //   tab_name: 'SOP',
      //   path: '/archive-management/sop'
      // }
    ]
  }),
  created() {
    // id 22 Umum (prod) | id 21 (dev)
    // id 28 Admin SOP | id 28 (dev)
    // id 32 Admin Treasury
    // const a = this.build === 'development' ? '19' : '20' // id 20 Admin Legal (prod) | id 20 (dev)
    // const b = this.build === 'development' ? '20' : '21' // id 21 DMS - Approver (prod) | id 19 (dev)
    const a = this.build === 'development' ? '20' : '20'
    const b = this.build === 'development' ? '19' : '21'
    var arr = this.getUserProfile.level
    this.setTabs(this.tabs)
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            if (
              arr[i].id === '1' ||
              arr[i].id === a ||
              arr[i].id === b ||
              arr[i].id === '32'
            ) {
              this.isAdmin = true
              this.initDataTable()
              this.initDataTable2()
            } else {
              this.isAdmin = false
            }
          })
          .catch(error => {
            if (error) {
              this.isAdmin = null
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = null
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions(['menu_access']),
    ...mapMutations(['setPermission', 'setTabs', 'setLoanDocNumber']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    initDataTable() {
      this.actionValue = 0
      this.selectedData = []
      this.result = []
      this.result2 = []
      this.result3 = []
      axios
        .get(
          `${this.eSanqua}archive_document/list?is_archived=&keyword=${this.paramAPI.keyword}&doc_status_id=${this.paramAPI.status}`
        )
        .then(res => {
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              // if (response[i].has_expire === 0) {
              if (this.countdown(response[i].doc_expiry_date) <= 7) {
                this.result.push({
                  archive_doc_no: response[i].archive_doc_no,
                  created_at: response[i].created_at,
                  created_by_name: response[i].created_by_name,
                  description: response[i].description,
                  doc_date: response[i].doc_date,
                  doc_expiry_date: response[i].doc_expiry_date,
                  doc_no: response[i].doc_no,
                  doc_qty: response[i].doc_qty,
                  doc_qty_unit: response[i].doc_qty_unit,
                  doc_status: response[i].doc_status,
                  document_type_id: response[i].document_type_id,
                  file: response[i].file,
                  id: response[i].id,
                  plant:
                    response[i].plant !== undefined ? response[i].plant : null,
                  // location_department_name:
                  //   response[i].location_department_name,
                  location_department_name:
                    response[i].location_department_name,
                  name: response[i].name,
                  pic_department_name: response[i].pic_department_name,
                  rack_index: response[i].rack_index,
                  updated_at: response[i].updated_at,
                  updated_by_name: response[i].updated_by_name
                })
              }
              // }
            }
          } else {
            this.result = []
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    clear() {
      this.detail = null
    },
    rowClick(pValue) {
      axios
        .get(`${this.eSanqua}archive_document/detail/${pValue.id}`)
        .then(res => {
          this.detail = res.data.data
          document.getElementById('archivedetail').click()
        })
        .catch(err => {
          console.log(err)
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        return raw.replace(/-/g, '/')
      }
    },
    countdown(raw) {
      if (raw !== null) {
        const day = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const year = raw.slice(raw.lastIndexOf('-') + 1)
        const z = new Date(year + '/' + month + '/' + day)

        const a = new Date()
        const n = a.toLocaleDateString('id')
        const day2 = n.slice(0, n.indexOf('/'))
        const month2 = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year2 = n.slice(n.lastIndexOf('/') + 1)
        let x = ''
        // if (navigator.userAgent.includes('Chrome')) {
        //   x = new Date(year2 + '/' + month2 + '/' + day2)
        // } else {
        x = new Date(year2 + '/' + month2 + '/' + day2)
        // }
        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)
        return q
      }
    },

    initDataTable2() {
      this.result2 = []
      this.result3 = []
      axios
        .get(
          `${this.eSanqua}archive_document/loan/list?keyword=${this.paramAPI2.keyword}&offset=&limit=&is_archived=&loan_start_date=&loan_end_date=&status=`
        )
        .then(res => {
          // this.result2 = res.data.data;
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              if (response[i].status !== null) {
                // ---------NEED APPROVE----------------
                if (response[i].status.id === 0) {
                  this.result3.push({
                    created_at: response[i].created_at,
                    created_by_name: response[i].created_by_name,
                    department: response[i].department,
                    doc: response[i].doc,
                    email: response[i].email,
                    employee: response[i].employee,
                    id: response[i].id,
                    loan_end_date: response[i].loan_end_date,
                    loan_number: response[i].loan_number,
                    loan_purpose: response[i].loan_purpose,
                    loan_start_date: response[i].loan_start_date,
                    pic_name: response[i].pic_name,
                    plant: response[i].plant,
                    qty: response[i].qty,
                    qty_uom: response[i].qty_uom,
                    status: response[i].status,
                    updated_at: response[i].updated_at,
                    updated_by_name: response[i].updated_by_name,
                    user_id: response[i].user_id
                  })
                }

                // ---------EXCEDED----------------
                if (
                  response[i].status.id === 2 ||
                  response[i].status.id === 10
                ) {
                  this.result2.push({
                    created_at: response[i].created_at,
                    created_by_name: response[i].created_by_name,
                    department: response[i].department,
                    doc: response[i].doc,
                    email: response[i].email,
                    employee: response[i].employee,
                    id: response[i].id,
                    loan_end_date: response[i].loan_end_date,
                    loan_number: response[i].loan_number,
                    loan_purpose: response[i].loan_purpose,
                    loan_start_date: response[i].loan_start_date,
                    pic_name: response[i].pic_name,
                    plant: response[i].plant,
                    qty: response[i].qty,
                    qty_uom: response[i].qty_uom,
                    status: response[i].status,
                    updated_at: response[i].updated_at,
                    updated_by_name: response[i].updated_by_name,
                    user_id: response[i].user_id
                  })
                }
              }
            }
          } else {
            this.result2 = []
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    rowClick2(pItem) {
      this.setLoanDocNumber(pItem.loan_number)
      this.$router.push('/archive-management/loaning-list')
    },
    convertDate2(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        return (day + '-' + month + '-' + year).replace(/-/g, '/')
      }
    },
    convertDate3(raw) {
      const date = new Date(raw)

      return date.toLocaleString('id')
    },
    countdown2(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        const z = new Date(year + '/' + month + '/' + day)

        const a = new Date()
        const n = a.toLocaleDateString('id')
        const day2 = n.slice(0, n.indexOf('/'))
        const month2 = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year2 = n.slice(n.lastIndexOf('/') + 1)
        const x = new Date(year2 + '/' + month2 + '/' + day2)

        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)

        return q
      }
    },
    refresh() {
      this.initDataTable()
      this.initDataTable2()
    }
  }
}
</script>
<style lang="scss" src="">
#requestList {
  margin-bottom: 10px;
  .request-list-div {
    padding: 10px 15px;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // .request-list-toolbar-1 {
    //   .request-list-toolbar-div {
    //     width: 50%;
    //     display: flex;
    //     flex-direction: row;
    //     margin-left: auto;
    //   }
    // }
    // .request-list-toolbar-2 {
    //   display: none;
    // }
  }
}

// .arch-add-new-btn {
//   padding: 1px 4px;
//   box-shadow: 1px 1px 1px rgb(134, 132, 132);
//   border: 1px solid #cacad4;
//   cursor: pointer;
//   margin-top: 5px;
//   text-align: left;
//   font-size: 12px;
//   &:hover {
//     box-shadow: 1px 2px 7px rgb(134, 132, 132);
//     color: blue;
//     text-decoration: underline;
//   }
// }

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 10px !important;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  // #requestList {
  //   .request-list-div {
  //     .request-list-toolbar-1 {
  //       .request-list-toolbar-div {
  //         display: none;
  //       }
  //     }
  //     .request-list-toolbar-2 {
  //       display: block;
  //     }
  //   }
  // }
}
</style>
